export const GET_ALL_SP_lIST = "GET_ALL_SP_LIST";
export const SP_LOADING = "SP_LOADING";
export const REMOVE_SP_LOADING = "REMOVE_SP_LOADING";

export const DELETE_SERVICE_PROVIDER = "DELETE_SERVICE_PROVIDER";
export const GET_SERVICE_PROVIDER_BY_ID = "GET_SERVICE_PROVIDER_BY_ID";
export const UPDATE_SERVICE_PROVIDER_STATUS = "UPDATE_SERVICE_PROVIDER_STATUS";
export const UPDATE_SERVICE_PROVIDER_PROFILE =
  "UPDATE_SERVICE_PROVIDER_PROFILE";

export const SERVICE_PROVIDER_ACTIVE_BOOKINGS =
  "SERVICE_PROVIDER_ACTIVE_BOOKINGS";
export const SERVICE_PROVIDER_COMPLETED_BOOKINGS =
  "SERVICE_PROVIDER_COMPLETED_BOOKINGS";
export const SERVICE_PROVIDER_RATING = "SERVICE_PROVIDER_RATING";

export const SEARCH_SERVICE_PROVIDER = "SEARCH_SERVICE_PROVIDER";

export const GET_ALL_SP_DOC = "GET_ALL_SP_DOC";

export const SP_EARNINGS = "SP_EARNINGS";
export const WALLET_DETAILS = "WALLET_DETAILS";
