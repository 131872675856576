export const PENDING_REFUND_DISPUTE_LIST = "PENDING_REFUND_DISPUTE_LIST";
export const GET_PENDING_REFUND_DISPUTE = "GET_PENDING_REFUND_DISPUTE";
export const EDIT_PENDING_REFUND_DISPUTE = "EDIT_PENDING_REFUND_DISPUTE";
export const DELETE_PENDING_REFUND_DISPUTE = "DELETE_PENDING_REFUND_DISPUTE";

export const REJECT_PENDING_REFUND_DISPUTE = "REJECT_PENDING_REFUND_DISPUTE";
export const APPROVE_PENDING_REFUND_DISPUTE = "APPROVE_PENDING_REFUND_DISPUTE";

export const DISPUTE_HISTORY_LIST = "DISPUTE_HISTORY_LIST";

export const APROVED_REFUND_DISPUTE_LIST = "APROVED_REFUND_DISPUTE_LIST";
export const GET_APROVED_REFUND_DISPUTE = "GET_APROVED_REFUND_DISPUTE";
export const EDIT_APROVED_REFUND_DISPUTE = "EDIT_APROVED_REFUND_DISPUTE";
export const DELETE_APROVED_REFUND_DISPUTE = "DELETE_APROVED_REFUND_DISPUTE";


export const REJECTED_REFUND_DISPUTE_LIST = "REJECTED_REFUND_DISPUTE_LIST";
export const GET_REJECTED_REFUND_DISPUTE = "GET_REJECTED_REFUND_DISPUTE";
export const EDIT_REJECTED_REFUND_DISPUTE = "EDIT_REJECTED_REFUND_DISPUTE";
export const DELETE_REJECTED_REFUND_DISPUTE = "DELETE_REJECTED_REFUND_DISPUTE";
