export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const REMOVE_CATEGORY_LOADING = "REMOVE_CATEGORY_LOADING";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const CREATE_CATEGORIES = "CREATE_CATEGORIES";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const GET_CATEGORIES_BY_ID = "GET_CATEGORIES_BY_ID";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SUBCATEGORY_CHILD = "GET_SUBCATEGORY_CHILD";
export const REGISTER_USER_AGAINEST_CATEGORY =
  "REGISTER_USER_AGAINEST_CATEGORY";
export const PARENT_CATERGORY_FILTER = "PARENT_CATERGORY_FILTER";
export const FILTER_CATEGORY_REGISTERED_USER =
  "FILTER_CATEGORY_REGISTERED_USER";
  export const CATEGORY_ACTIVE =
  "CATEGORY_ACTIVE";
