export const POST_COMMON_CATEGORY_KYC = 'POST_COMMON_CATEGORY_KYC'
export const GET_COMMON_CATEGORY_KYC_LIST = "GET_COMMON_CATEGORY_KYC_LIST";
export const GET_COMMON_CATEGORY_KYC = "GET_COMMON_CATEGORY_KYC";
export const EDIT_COMMON_CATEGORY_KYC = "EDIT_COMMON_CATEGORY_KYC";
export const DELETE_COMMON_CATEGORY_KYC = "DELETE_COMMON_CATEGORY_KYC";

export const POST_SPECIFIC_CATEGORY_KYC = 'POST_SPECIFIC_CATEGORY_KYC'
export const GET_SPECIFIC_CATEGORY_KYC = "GET_SPECIFIC_CATEGORY_KYC";
export const EDIT_SPECIFIC_CATEGORY_KYC = "EDIT_SPECIFIC_CATEGORY_KYC";
export const DELETE_SPECIFIC_CATEGORY_KYC = "DELETE_SPECIFIC_CATEGORY_KYC";
export const CATEGORY_SPECFIC_KYC_LIST = "CATEGORY_SPECFIC_KYC_LIST";

export const COMMOM_QUESTIONS = "COMMOM_QUESTIONS";


export const KYC_ANSWERS_LIST = 'KYC_ANSWERS_LIST'
export const GET_KYC_ANSWERS = "GET_KYC_ANSWERS";
export const EDIT_KYC_ANSWERS = "EDITKYC_ANSWERS";
export const DELETE_KYC_ANSWERS = "DELETE_KYC_ANSWERS";