export const ADD_PRIVICY_POLICY = "ADD_PRIVICY_POLICY";
export const GET_POLICY_CONTENT = "GET_POLICY_CONTENT";
export const EDIT_PRIVICY_POLICY_CONTENT = "EDIT_PRIVICY_POLICY_CONTENT";

export const ADD_TERMS_CONDITIONS = "ADD_TERMS_CONDITIONS";
export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";
export const EDIT_TERMS_CONDITIONS = "EDIT_TERMS_CONDITIONS";

export const ADD_ABOUT_US = "ADD_ABOUT_US";
export const GET__ABOUT_US = "GET__ABOUT_US";
export const EDIT_ABOUT_US_CONTENT = "EDIT_ABOUT_US_CONTENT";
