export const GET_APPROVED_DOCUMENTS="GET_APPROVED_DOCUMENTS";
export const EDIT_APPROVED_DOC = "EDIT_APPROVED_DOC";
export const GET_DOC_BY_ID = "GET_DOC_BY_ID";
export const DELETE_APPROVED_DOC = "DELETE_APPROVED_DOC";
export const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";


export const GET_PENDING_DOC_lIST = 'GET_PENDING_DOC_lIST'
export const DELETE_PENDING_DOC = "DELETE_PENDING_DOC";
export const GET_PENDING_DOC_BY_ID = "GET_PENDING_DOC_BY_ID";


export const PENDING_DOC_LOADING = 'PENDING_DOC_LOADING'
export const REMOVE_PENDING_DOC_LOADING = 'REMOVE_PENDING_DOC_LOADING'


export const CREATE_KYC_QUESTION = "CREATE_KYC_QUESTION"
export const KYC_QUESTION_LIST = "KYC_QUESTION_LIST"
export const DELETE_KYC_QUESTION = "DELETE_KYC_QUESTION"
