export const MLN_LIST = "MLN_LIST";
export const GET_MLN_BY_ID = "GET_MLN_BY_ID";
export const GET_MLN_WITHOUT_REFERALS_BY_ID = "GET_MLN_WITHOUT_REFERALS_BY_ID";
export const GET_MLN_REFERALS_BY_ID = "GET_MLN_REFERALS_BY_ID";
export const GET_PROFIT_MARGIN = "GET_PROFIT_MARGIN";
export const SET_PROFIT_MARGIN = "SET_PROFIT_MARGIN";
export const GET_FEE_MARGIN = "GET_FEE_MARGIN";
export const SET_FEE_MARGIN = "SET_FEE_MARGIN";
export const GET_TOKEN_RATE = "GET_TOKEN_RATE";
export const SET_TOKEN_RATE = "SET_TOKEN_RATE";
export const MLN_FILTER = "MLN_FILTER";
